::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.3);
}
::-webkit-scrollbar-track {
  border-radius: 1em;
  background-color: rgba(50, 50, 50, 0.1);
}

.ant-message {
  z-index: 2023;
}

.pageHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 8vh;
  padding: 0 64px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1001;
}

.pageHeader img.mainHeaderLogo {
  width: 160px;
  cursor: pointer;
}

.pageHeader.blurHeader {
  background-color: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(4px);
}

.pageHeader .headerTabs .ant-tabs-tab {
  padding: 10px 0;
  color: #666e82;
  font-size: 16px;
  font-weight: 500px;
}

.pageHeader .headerTabs .ant-tabs-tab:hover {
  color: #448aff;
}

.pageHeader .headerTabs .ant-tabs-tab:active {
  color: #1233a2;
}

.pageHeader .ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #155de8;
}

.pageHeader .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.pageHeader .ant-tabs-ink-bar {
  background: transparent;
}

.pageHeader .ant-tabs-ink-bar::after {
  content: "";
  position: absolute;
  width: 24px;
  height: 100%;
  left: 50%;
  transform: translateX(-50%);
  background: #155de8;
}

.videoArea {
  position: relative;
  height: 100vh;
  overflow: hidden;
}

.videoArea .playerAction {
  z-index: 100;
  position: absolute;
  bottom: 133px;
  left: 64px;
  display: flex;
}

.playerAction .icon {
  margin-right: 30px;
  cursor: pointer;
  fill: #000630;
}

.playerAction .icon:hover {
  fill: #155de8;
}

.playerAction .icon:active {
  fill: #1233a2;
}

/* 
.screen1content {
  background: url(./imgs/grid.svg) repeat-x left bottom,
    url(./imgs/ellipse.svg) no-repeat right -55% bottom 0;
  background-size: 100%, 75%;
  height: 100%;
  position: relative;
} 
*/

.screen1content {
  background: url(./imgs/grid.svg) repeat-x left bottom;
  background-size: 100%;
  height: 100%;
  position: relative;
  display: flex;
  z-index: 99;
}

.screen1content .bgBall {
  position: absolute;
  right: -12vw;
  bottom: 0;
  z-index: 2;
}

.bgBall img {
  width: 100%;
}

.screen1left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 64px;
}

.screen1left .s1leftButtons {
  display: flex;
}

.s1leftButtons .leftButtons {
  width: 128px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
}

.leftButtons.btnLeft {
  background: #155de8;
  margin-right: 24px;
}

.leftButtons.btnLeft.ant-btn-primary:hover {
  background: #448aff;
}

.leftButtons.btnLeft.ant-btn-primary:active {
  background: #1233a2;
}

.leftButtons.btnRight {
  color: #155de8;
  border-color: #155de8;
}

.leftButtons.btnRight.ant-btn-default:hover {
  color: #448aff;
  border-color: #448aff;
}

.leftButtons.btnRight.ant-btn-default:active {
  color: #1233a2;
  border-color: #1233a2;
}

.screenTitle {
  font-size: 64px;
  line-height: 64px;
  font-weight: 700;
  color: #000630;
  margin-bottom: 16px;
  white-space: nowrap;
  letter-spacing: 0.09em;
}

.screenTitle .blueText {
  color: #155de8;
}

.screenSubTitle {
  color: #595959;
  font-size: 26px;
  font-weight: 400;
  letter-spacing: 0.2em;
  margin-bottom: 40px;
}

.screen1right {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  margin-left: -34vw;
  width: 68vw;
  z-index: 9;
  display: flex;
  align-items: center;
}

.screen1right .screen1Lottie {
  width: 100%;
}

.emoteArea {
  background: url(./imgs/s2bg.svg) no-repeat top center;
  background-size: cover;
  height: 100vh;
  position: relative;
  overflow: hidden;
}

.emoteArea img {
  position: absolute;
}

.emoteArea .painText {
  position: absolute;
  top: 85vh;
  left: 0;
  right: 0;
  width: 100%;
  text-align: center;
  color: #155de8;
  font-weight: 500;
  font-size: 24px;
}

.emoteArea .arrowLottie {
  position: absolute;
  top: 90vh;
  left: 50%;
  width: 4vw;
  margin-left: -2vw;
}

.arrowContainer {
  position: absolute;
  top: 90vh;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}
.arrow {
  position: absolute;
  width: 1.9rem;
  height: 0.25rem;
  opacity: 0;
  transform: scale(0.3);
  animation: move-arrow 3s ease-out infinite;
}
.arrow:first-child {
  animation: move-arrow 3s ease-out 1s infinite;
}
.arrow:nth-child(2) {
  animation: move-arrow 3s ease-out 2s infinite;
}
.arrow:before,
.arrow:after {
  content: "";
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #155de8;
}
.arrow:before {
  left: 0;
  transform: skewY(40deg);
}
.arrow:after {
  right: 0;
  width: 50%;
  transform: skewY(-40deg);
}
@keyframes move-arrow {
  25% {
    opacity: 1;
  }

  33.3% {
    opacity: 1;
    transform: translateY(2.28rem);
  }

  66.6% {
    opacity: 1;
    transform: translateY(3.12rem);
  }

  100% {
    opacity: 0;
    transform: translateY(4.8rem) scale(0.5);
  }
}

.tabsArea {
  height: 100vh;
  overflow: hidden;
}

.tabsArea .title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 40px;
  color: #000630;
  height: 22vh;
}

.tabsArea .swiperSlider {
  height: calc(78vh - 62px);
}

.tabsArea .swiperSlider .slider {
  height: 100%;
  background-size: 100%;
  margin-left: -1px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.slider.slider1 {
  background: url(./imgs/slider1.svg) no-repeat left bottom;
}

.slider.slider2 {
  background: url(./imgs/slider2.svg) no-repeat left bottom;
}

.slider.slider3 {
  background: url(./imgs/slider3.svg) no-repeat left bottom;
}

.slider.slider4 {
  background: url(./imgs/slider4.svg) no-repeat left bottom;
}

.slider .left {
  width: 35vw;
}

.slider .rightTabPanel {
  margin-right: 10vw;
  margin-left: 2vw;
}

.rightTabPanel .raTitle {
  color: #000630;
  font-size: 32px;
  font-weight: 500;
  margin-bottom: 32px;
}

.rightTabPanel .raSubTitle {
  color: #666e82;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  margin-bottom: 32px;
  text-align: justify;
  text-justify: inter-ideograph;
}

.rightTabPanel .roundTag {
  display: flex;
  align-items: center;
}

.roundTag .item {
  display: flex;
  align-items: center;
  margin-right: 32px;
  color: #666e82;
  white-space: nowrap;
}

.roundTag .item img {
  width: 8px;
  margin-right: 8px;
}

.anChorScreen .raTitle,
.anChorScreen .raSubTitle {
  margin-bottom: 48px;
}

.anChorScreen .raTitle::before {
  display: block;
  width: 64px;
  height: 4px;
  background: #155de8;
  border-radius: 100px;
  content: " ";
  margin-bottom: 24px;
}

.anChorScreen .rightTabPanel {
  padding: 0 3vw;
}

.tabsArea .tabLabel {
  font-size: 24px;
  font-weight: 400;
  color: #666e82;
}

.tabsArea .tabLabel:hover {
  color: #448aff;
}

.tabsArea .ant-tabs-tab-active .tabLabel {
  color: #155de8;
}

.tabsArea .tabLabel:active {
  color: #1233a2;
}

.slideFormRight {
  height: 100vh;
}

.slideFormRight .topArea {
  position: relative;
  padding: 17vh 0 10vh;
}

.topArea .title {
  font-weight: 500;
  text-align: center;
  font-size: 40px;
  color: #000630;
}

.topArea .crane {
  position: absolute;
  right: 0;
  bottom: -15px;
  width: 25vw;
}

.trackArea .blueTrack {
  width: 100%;
  background: #155de8;
  height: 2vw;
}

.trackArea .trackContainer {
  display: flex;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: -2vw;
  overflow: hidden;
}

.trackContainer img.trackImg {
  width: 28vw;
}

.flyShape1 {
  width: 5vw;
  height: 5vw;
  background: #f65b38;
  position: fixed;
  z-index: 1090;
  right: 8vw;
  bottom: 4.9vw;
  transform: rotate(-45deg);
}

.flyShape2 {
  width: 0;
  height: 0;
  border-left: 2.5vw solid transparent;
  border-right: 2.5vw solid transparent;
  border-bottom: 4.2vw solid #f65b38;
  position: fixed;
  z-index: 1090;
  left: 17.5vw;
  bottom: 18vw;
  transform: rotate(15deg);
}

.flyShape3 {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background: #f65b38;
  position: fixed;
  z-index: 1090;
  right: 13.3vw;
  bottom: 10.5vw;
}

.jumpShape1 {
  width: 5vw;
  height: 5vw;
  background: #f65b38;
  position: absolute;
  z-index: 1090;
  right: 8vw;
  bottom: 4.9vw;
}

.jumpShape2 {
  position: absolute;
  z-index: 1090;
  left: 2.5vw;
  bottom: 10vw;
  width: 0;
  height: 0;
  border-left: 2.5vw solid transparent;
  border-right: 2.5vw solid transparent;
  border-bottom: 4.2vw solid #f65b38;
}

.jumpShape3 {
  width: 3vw;
  height: 3vw;
  border-radius: 50%;
  background: #f65b38;
  position: absolute;
  z-index: 1090;
  right: 13.3vw;
  bottom: 10.5vw;
}

.jumpShape4 {
  position: absolute;
  z-index: 1090;
  left: 13.2vw;
  bottom: 26.1vw;
  width: 0;
  height: 0;
  border-left: 7vw solid #f65b38;
  border-top: 2vw solid transparent;
  border-bottom: 2vw solid transparent;
  transform-origin: right;
  transform: rotate(60deg);
}

.jumpShape04 {
  position: absolute;
  z-index: 1090;
  left: 13.2vw;
  bottom: 26.1vw;
  width: 0;
  height: 0;
  border-left: 7vw solid #155de8;
  border-top: 2vw solid transparent;
  border-bottom: 2vw solid transparent;
  transform-origin: right;
  transform: rotate(30deg);
}

.anChorScreen {
  width: 100%;
  height: 400vh;
  overflow: hidden;
  background: #f7f8fc;
}

.anChorScreen .anChorScreen1 {
  height: 100vh;
  margin-left: 15vw;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.anChorScreen1 .asTitle {
  font-size: 40px;
  font-weight: 500;
  color: #000630;
  height: 24vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.anChorScreen1 .as1content {
  flex: 1;
  position: relative;
}

.as1content .as1img {
  width: 35vw;
  position: absolute;
  right: 4vw;
  bottom: 1%;
}

.as1content .rightTabPanel.scrollTextArea {
  position: absolute;
  left: 0;
  bottom: 15%;
  width: 30vw;
}

.anChorScreen .anChorScreen2 {
  overflow: hidden;
  position: relative;
  box-sizing: border-box;
  height: 100vh;
  margin-left: 15vw;
}

.anChorScreen2 .as2img {
  width: 40vw;
  position: absolute;
  left: 4vw;
  bottom: 10%;
}

.anChorScreen2 .rightTabPanel.scrollTextArea {
  position: absolute;
  right: 4vw;
  bottom: 15%;
  width: 30vw;
}

.anChorScreen .anchorTab {
  float: left;
  position: relative;
  top: 20vh;
}

.anChorScreen3 .rightTabPanel.scrollTextArea {
  left: 4vw;
  right: unset;
}

.anChorScreen3 .as2img {
  width: 40vw;
  right: 4vw;
  left: unset;
  bottom: 10%;
}

/* 激活状态锚点前的样式 */
.anchorTab .ant-anchor-ink .ant-anchor-ink-ball {
  width: 48px;
  background-color: transparent;
  border-bottom: 1px solid #155de8;
  transform: translateY(calc(-50% - 12px));
}

/* 锚点文字 */
.anchorTab .ant-anchor .ant-anchor-link {
  padding-block: 12px;
  padding-inline: 64px 0;
  font-size: 400;
}

.anchorTab .ant-anchor .ant-anchor-link-title {
  color: #666e82;
}

.anchorTab .ant-anchor .ant-anchor-link-title:hover {
  color: #448aff;
}

.anchorTab .ant-anchor .ant-anchor-link-title:active {
  color: #1233a2;
}

.anchorTab .ant-anchor .ant-anchor-link.ant-anchor-link-active {
  font-size: 16px;
  font-weight: 400;
}

.anchorTab
  .ant-anchor
  .ant-anchor-link.ant-anchor-link-active
  .ant-anchor-link-title {
  color: #155de8;
}

.sixAbilitiesScreen {
  overflow: hidden;
  height: 100vh;
  background: #fbfcff;
}

.sixAbilitiesScreen .title {
  height: 25vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.sixAbilitiesScreen .title1 {
  color: #000630;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 12px;
  text-align: center;
}

.sixAbilitiesScreen .title2 {
  color: #666e82;
  font-weight: 400;
  font-size: 24px;
  text-align: center;
}

.sixAbilitiesScreen .content {
  background: url(./imgs/grid.svg) no-repeat center center;
  background-size: 100%;
  position: relative;
  width: 100vw;
}

.sixAbilitiesScreen .content .sixAbilitiesLottie {
  width: 54%;
  margin: 0 auto;
}

.content .textBlock {
  position: absolute;
  width: 16vw;
  color: #666e82;
  font-size: 12px;
  line-height: 18px;
  font-weight: 400;
  text-align: justify;
  text-justify: inter-ideograph;
}

.textBlock.leftTop {
  top: 5vw;
  left: 18vw;
}

.textBlock.leftCenter {
  top: 13vw;
  left: 8vw;
}

.textBlock.leftBottom {
  top: 26.5vw;
  left: 16.5vw;
}

.textBlock.rightTop {
  top: 5vw;
  right: 17.5vw;
}

.textBlock.rightCenter {
  top: 13vw;
  right: 7.5vw;
}

.textBlock.rightBottom {
  top: 29.5vw;
  right: 16vw;
}

.twelveScreen {
  background: url(./imgs/12icons/bg.svg) no-repeat right bottom;
  background-size: cover;
  height: 120vh;
}

.twelveScreen .tlvArea {
  padding: 0 3vw;
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
}

.tlvArea .twvImgItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 25%;
  height: 14vw;
  box-sizing: border-box;
  border-top: 1px dashed #ccd1df;
  border-left: 1px dashed #ccd1df;
}

.tlvArea .twvImgItem:nth-child(-n + 4) {
  border-top: 0;
}

.tlvArea .twvImgItem:nth-child(4n + 1) {
  border-left: 0;
}

.twvImgItem img {
  width: 25%;
  margin-bottom: 1vw;
  vertical-align: bottom;
}

.twvImgItem .itemTitles {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #666e82;
}

.twvImgItem .itemTitles .mainTitle {
  color: #000630;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
}

.numberScreen {
  height: 100vh;
  background: url(./imgs/rst.jpg) no-repeat left top;
  background-size: 50vw 100vh;
  overflow: hidden;
}

.numberScreen .rightArea {
  overflow: hidden;
  float: right;
  width: calc(50vw - 128px);
  height: 100vh;
  padding: 0 64px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.rightArea .title1 {
  font-weight: 500;
  color: #000630;
  font-size: 40px;
  line-height: 60px;
}

.rightArea .title2 {
  font-size: 16px;
  color: #666e82;
  line-height: 28px;
  margin: 32px 0 64px;
}

.rightArea .numArea {
  display: flex;
  flex-wrap: wrap;
}

.numArea .nums {
  width: 50%;
  margin-bottom: 24px;
}

.nums .top {
  display: flex;
  align-items: baseline;
  color: #155de8;
  font-weight: 500;
  font-size: 32px;
}

.top .bigNum {
  font-size: 64px;
  margin-right: 4px;
  font-weight: 900;
  font-family: "Avenir";
}

@font-face {
  font-family: "Avenir";
  src: url("./font/Avenir-Book-woff-5.ttf");
}

.nums .bottom {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666e82;
}

.pageFooter {
  background: #155de8;
  padding: 56px 64px;
}

.pageFooter .footerTop {
  border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
  padding-bottom: 24px;
  margin-bottom: 24px;
  display: flex;
  align-items: flex-start;
}

.footerTop dl,
.footerTop dt,
.footerTop dd {
  padding: 0;
  margin: 0;
  color: rgba(255, 255, 255, 0.7);
  font-size: 14px;
  font-weight: 400;
}

.footerTop dl {
  margin-right: 120px;
}

.footerTop dt {
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  margin-bottom: 16px;
}

.footerTop dd {
  margin-top: 10px;
}

.footerTop .left dd {
  cursor: pointer;
}

.footerTop .left dd:hover {
  color: #ffffff;
}

.copyrightInfo {
  text-align: right;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.5);
}

.copyrightInfo a {
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
}

.copyrightInfo a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.emaillink {
  margin-left: 8px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.7);
  text-decoration: none;
}

.emaillink:hover {
  color: #ffffff;
  text-decoration: underline;
}

/* 联系我们 */
.drawerClose {
  font-size: 18px;
  cursor: pointer;
  color: #666e82;
}

.drawerClose:hover {
  color: #000630;
}

.drawerContainer {
  height: 100%;
  display: flex;
  align-items: center;
}

.drawerContainer .drwLeft {
  width: 40vw;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #000630;
  font-size: 16px;
  font-weight: 400;
}

.drwLeft .iconlink {
  padding: 48px 0 54px;
  display: flex;
  align-items: flex-start;
}

.iconlink .elink {
  text-decoration: underline;
  color: #155de8;
  font-size: 16px;
  font-weight: 500;
  margin-left: 16px;
}

.drawerContainer .drwRight {
  flex: 1;
  padding-right: 64px;
}

.drwRight .title {
  display: flex;
  align-items: center;
  font-size: 32px;
  color: #000630;
  font-weight: 500;
  margin-bottom: 24px;
}

.title .right {
  display: flex;
  align-items: flex-end;
  margin-left: 48px;
}

.title .right img {
  margin-right: 8px;
}

.drwRight .subTitle {
  color: #000630;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 52px;
}

.drwRight .leftButtons {
  width: 96px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
}

.formItems .flexBetween {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.formItems .item {
  width: 47%;
  position: relative;
  margin-bottom: 50px;
}

.formItems .item input {
  outline: none;
  border: none;
  width: 100%;
  padding: 6px 0;
  color: #000;
  font-size: 16px;
  background-image: linear-gradient(#7d8197, #7d8197),
    linear-gradient(#ccd1df, #ccd1df);
  background-size: 0 2px, 100% 1px;
  background-position: left bottom, center calc(100% - 1px);
  background-repeat: no-repeat;
  background-color: transparent;
}

.formItems .item input:focus {
  background-size: 100% 2px, 100% 1px;
  transition: all 0.4s;
}
.formItems .item input:not(:focus) {
  background-size: 0 2px, 100% 1px;
  transition: all 0.4s;
}

.formItems .item label {
  position: absolute;
  top: 0;
  left: 0;
  color: #666e82;
  font-size: 14px;
  pointer-events: none;
  transition: all 0.5s;
}
.formItems .item input:focus + label {
  font-size: 12px;
  top: -20px;
}
.formItems .item input.inputed:not(:focus) + label {
  font-size: 12px;
  top: -20px;
}
