body {
  margin: 0;
  font-family: "Source Han Sans CN";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: "Source Han Sans CN";
  src: url("./font/SourceHanSansCNRegular.ttf");
}

code {
  font-family: "Source Han Sans CN";
}
