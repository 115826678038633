@media screen and (max-width: 1440px) {
  .pageHeader {
    height: 8vh;
    padding: 0 48px;
  }

  .screen1left {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-left: 48px;
  }

  .screen1left .leftButtons {
    width: 128px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
  }

  .leftButtons.btnLeft {
    margin-right: 16px;
  }

  .screenTitle {
    font-size: 48px;
    line-height: 48px;
    font-weight: 700;
    color: #000630;
    margin-bottom: 16px;
    white-space: nowrap;
  }

  .screenTitle .blueText {
    color: #155de8;
  }

  .screenSubTitle {
    color: #595959;
    font-size: 19.5px;
    font-weight: 400;
    letter-spacing: 0.2em;
    margin-bottom: 40px;
  }

  .screen1right {
    position: absolute;
  }

  .emoteArea {
    background: url(./imgs/s2bg.svg) no-repeat top center;
    background-size: cover;
    height: 100vh;
    position: relative;
    overflow: hidden;
  }

  .emoteArea .painText {
    position: absolute;
    top: 85vh;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    color: #155de8;
    font-weight: 500;
    font-size: 18px;
  }

  .tabsArea .title {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #000630;
    height: 22vh;
  }

  .tabsArea .swiperSlider {
    height: calc(78vh - 62px);
  }

  .tabsArea .swiperSlider .slider {
    height: 100%;
    background-size: 100%;
    margin-left: -1px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .slider .left {
    width: 35vw;
  }

  .slider .rightTabPanel {
    margin-right: 10vw;
    margin-left: 2vw;
  }

  .rightTabPanel .raTitle {
    color: #000630;
    font-size: 24px;
    margin-bottom: 24px;
  }

  .rightTabPanel .raSubTitle {
    color: #666e82;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 24px;
    text-align: justify;
    text-justify: inter-ideograph;
  }

  .rightTabPanel .roundTag {
    display: flex;
    align-items: center;
  }

  .roundTag .item {
    display: flex;
    align-items: center;
    margin-right: 24px;
    color: #666e82;
    font-size: 14px;
    font-weight: 400;
  }

  .roundTag .item img {
    width: 8px;
    margin-right: 8px;
  }

  .anChorScreen .raTitle,
  .anChorScreen .raSubTitle {
    margin-bottom: 48px;
  }

  .anChorScreen .raTitle::before {
    display: block;
    width: 64px;
    height: 4px;
    background: #155de8;
    border-radius: 100px;
    content: " ";
    margin-bottom: 24px;
  }

  .anChorScreen .rightTabPanel {
    padding: 0 3vw;
  }

  .tabsArea .tabLabel {
    font-size: 18px;
    font-weight: 400;
    color: #666e82;
  }

  .tabsArea .ant-tabs-tab-active .tabLabel {
    color: #155de8;
    font-weight: 500;
  }

  .slideFormRight {
    height: 100vh;
  }

  .slideFormRight .topArea {
    position: relative;
    padding: 17vh 0 10vh;
  }

  .topArea .title {
    text-align: center;
    font-size: 30px;
    color: #000630;
  }

  .topArea .crane {
    position: absolute;
    right: 0;
    bottom: -15px;
    width: 25vw;
  }

  .trackArea .blueTrack {
    width: 100%;
    background: #155de8;
    height: 2vw;
  }

  .trackArea .trackContainer {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;
    margin-top: -2vw;
    overflow: hidden;
  }

  .trackContainer img.trackImg {
    width: 28vw;
  }

  .jumpShape1 {
    right: 9vw;
    bottom: 5.5vw;
  }

  .jumpShape3 {
    bottom: 11.7vw;
  }

  .jumpShape4 {
    bottom: 27.3vw;
  }

  .jumpShape04 {
    bottom: 27.3vw;
  }

  .anChorScreen {
    overflow: hidden;
    background: #f7f8fc;
  }

  .anChorScreen .anChorScreen1 {
    height: 100vh;
    margin-left: 15vw;
    display: flex;
    flex-direction: column;
  }

  .anChorScreen1 .asTitle {
    text-align: center;
    font-size: 30px;
    color: #000630;
    padding: 12vh 0;
  }

  .anChorScreen1 .as1content {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .as1content .as1img {
    width: 40vw;
  }

  .anChorScreen .anChorScreen2 {
    height: 100vh;
    margin-left: 15vw;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  .anChorScreen2 .as2img {
    width: 40vw;
  }

  .anChorScreen .anchorTab {
    float: left;
    position: relative;
    top: 20vh;
  }

  /* 激活状态锚点前的样式 */
  .anchorTab .ant-anchor-ink .ant-anchor-ink-ball {
    width: 48px;
    background-color: transparent;
    border-bottom: 1px solid #155de8;
    transform: translateY(calc(-50% - 12px));
  }

  /* 锚点文字 */
  .anchorTab .ant-anchor .ant-anchor-link {
    padding-block: 12px;
    padding-inline: 64px 0;
    font-size: 400;
  }

  .anchorTab .ant-anchor .ant-anchor-link-title {
    color: #666e82;
    font-size: 12px;
  }

  .anchorTab .ant-anchor .ant-anchor-link-title:hover {
    color: #448aff;
  }

  .anchorTab .ant-anchor .ant-anchor-link-title:active {
    color: #1233a2;
  }

  .anchorTab .ant-anchor .ant-anchor-link.ant-anchor-link-active {
    font-size: 14px;
    font-weight: 500;
  }

  .anchorTab
    .ant-anchor
    .ant-anchor-link.ant-anchor-link-active
    .ant-anchor-link-title {
    color: #155de8;
  }

  .sixAbilitiesScreen {
    height: 100vh;
    background: #fbfcff;
  }

  .sixAbilitiesScreen .title {
    height: 20vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
  }

  .sixAbilitiesScreen .title1 {
    color: #000630;
    font-size: 30px;
    margin-bottom: 12px;
    text-align: center;
  }

  .sixAbilitiesScreen .title2 {
    color: #666e82;
    font-weight: 400;
    font-size: 18px;
    text-align: center;
  }

  .content .textBlock {
    position: absolute;
    width: 16vw;
    color: #666e82;
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    text-align: justify;
    text-justify: inter-ideograph;
  }

  .textBlock.leftTop {
    top: 5vw;
    left: 18.5vw;
  }

  .textBlock.leftCenter {
    top: 14vw;
    left: 9vw;
  }

  .textBlock.leftBottom {
    top: 28vw;
    left: 16.5vw;
  }

  .textBlock.rightTop {
    top: 5vw;
    right: 18vw;
  }

  .textBlock.rightCenter {
    top: 13.5vw;
    right: 8vw;
  }

  .textBlock.rightBottom {
    top: 29vw;
    right: 16vw;
  }

  .twelveScreen {
    background: url(./imgs/12icons/bg.svg) no-repeat right bottom;
    background-size: cover;
    height: 120vh;
  }

  .twelveScreen .tlvArea {
    padding: 0 3vw;
    margin-top: 80px;
    display: flex;
    flex-wrap: wrap;
  }

  .tlvArea .twvImgItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 25%;
    height: 14vw;
    box-sizing: border-box;
    border-top: 1px dashed #ccd1df;
    border-left: 1px dashed #ccd1df;
  }

  .tlvArea .twvImgItem:nth-child(-n + 4) {
    border-top: 0;
  }

  .tlvArea .twvImgItem:nth-child(4n + 1) {
    border-left: 0;
  }

  .twvImgItem img {
    width: 25%;
    margin-bottom: 1vw;
    vertical-align: bottom;
  }

  .twvImgItem .itemTitles {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: #666e82;
  }

  .twvImgItem .itemTitles .mainTitle {
    color: #000630;
    font-size: 18px;
    font-weight: 500;
    line-height: 27px;
  }

  .numberScreen {
    height: 100vh;
    background: url(./imgs/rst.jpg) no-repeat left top;
    background-size: 50vw 100vh;
    overflow: hidden;
  }

  .numberScreen .rightArea {
    overflow: hidden;
    float: right;
    width: calc(50vw - 128px);
    height: 100vh;
    padding: 0 64px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .rightArea .title1 {
    color: #000630;
    font-size: 30px;
    line-height: 45px;
  }

  .rightArea .title2 {
    font-weight: 400;
    font-size: 14px;
    color: #666e82;
    line-height: 21px;
    margin: 24px 0 56px;
  }

  .rightArea .numArea {
    display: flex;
    flex-wrap: wrap;
  }

  .numArea .nums {
    width: 50%;
    margin-bottom: 24px;
  }

  .nums .top {
    display: flex;
    align-items: baseline;
    color: #155de8;
    font-weight: 500;
    font-size: 24px;
  }

  .top .bigNum {
    font-size: 48px;
    margin-right: 4px;
  }

  .nums .bottom {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #666e82;
  }

  .pageFooter {
    background: #155de8;
    padding: 56px 64px;
  }

  .pageFooter .footerTop {
    border-bottom: 1px dashed rgba(255, 255, 255, 0.3);
    padding-bottom: 24px;
    margin-bottom: 24px;
    display: flex;
    align-items: flex-start;
  }

  .footerTop dl,
  .footerTop dt,
  .footerTop dd {
    padding: 0;
    margin: 0;
    color: rgba(255, 255, 255, 0.7);
    font-size: 14px;
  }

  .footerTop dl {
    margin-right: 120px;
  }

  .footerTop dt {
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    margin-bottom: 16px;
  }

  .footerTop dd {
    margin-top: 10px;
  }

  .footerTop .left dd {
    cursor: pointer;
  }

  .footerTop .left dd:hover {
    color: #ffffff;
  }

  .copyrightInfo {
    text-align: right;
    font-size: 12px;
    color: rgba(255, 255, 255, 0.5);
  }

  .copyrightInfo a {
    color: rgba(255, 255, 255, 0.5);
    text-decoration: none;
  }

  .copyrightInfo a:hover {
    color: #ffffff;
    text-decoration: underline;
  }

  .emaillink {
    margin-left: 8px;
    cursor: pointer;
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }

  .emaillink:hover {
    color: #ffffff;
    text-decoration: underline;
  }

  /* 联系我们 */
  .drawerClose {
    font-size: 18px;
    cursor: pointer;
    color: #666e82;
  }

  .drawerClose:hover {
    color: #000630;
  }

  .drawerContainer {
    height: 100%;
    display: flex;
    align-items: center;
  }

  .drawerContainer .drwLeft {
    width: 40vw;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: #000630;
    font-size: 16px;
    font-weight: 400;
  }

  .drwLeft .iconlink {
    padding: 48px 0 54px;
    display: flex;
    align-items: flex-start;
  }

  .iconlink .elink {
    text-decoration: underline;
    color: #155de8;
    font-size: 16px;
    font-weight: 500;
    margin-left: 16px;
  }

  .drawerContainer .drwRight {
    flex: 1;
    padding-right: 64px;
  }

  .drwRight .title {
    display: flex;
    align-items: center;
    font-size: 32px;
    color: #000630;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .title .right {
    display: flex;
    align-items: flex-end;
    margin-left: 48px;
  }

  .title .right img {
    margin-right: 8px;
  }

  .drwRight .subTitle {
    color: #000630;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 52px;
  }

  .drwRight .leftButtons {
    width: 96px;
    height: 48px;
    border-radius: 4px;
    font-size: 16px;
  }

  .formItems .flexBetween {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .formItems .item {
    width: 47%;
    position: relative;
    margin-bottom: 50px;
  }

  .formItems .item input {
    outline: none;
    border: none;
    width: 100%;
    padding: 6px 0;
    color: #000;
    font-size: 16px;
    background-image: linear-gradient(#7d8197, #7d8197),
      linear-gradient(#ccd1df, #ccd1df);
    background-size: 0 2px, 100% 1px;
    background-position: left bottom, center calc(100% - 1px);
    background-repeat: no-repeat;
    background-color: transparent;
  }

  .formItems .item input:focus {
    background-size: 100% 2px, 100% 1px;
    transition: all 0.4s;
  }
  .formItems .item input:not(:focus) {
    background-size: 0 2px, 100% 1px;
    transition: all 0.4s;
  }

  .formItems .item label {
    position: absolute;
    top: 0;
    left: 0;
    color: #666e82;
    font-size: 14px;
    pointer-events: none;
    transition: all 0.5s;
  }
  .formItems .item input:focus + label {
    font-size: 12px;
    top: -20px;
  }
  .formItems .item input.inputed:not(:focus) + label {
    font-size: 12px;
    top: -20px;
  }
}
