.aboutFirst {
  height: 100vh;
  background: url(./imgs/about1bg.svg) no-repeat bottom center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.aboutFirst .afLeft {
  margin-left: 64px;
  position: relative;
  top: -40px;
}

.aboutFirst .afLeft .titles {
  margin-bottom: 52px;
}

.afLeft .title1 {
  font-size: 64px;
  font-weight: 700;
  line-height: 64px;
  color: #000638;
}
.afLeft .title2 {
  font-size: 64px;
  font-weight: 700;
  color: #155de8;
  line-height: 64px;
  margin: 16px 0;
}

.afLeft .title2.joinTitle2 {
  line-height: 96px;
}

.afLeft .title3 {
  font-size: 26px;
  font-weight: 400;
  color: #666e82;
  line-height: 46px;
  white-space: nowrap;
}

.aboutFirst .afRight {
  width: 50vw;
}

.afLeft .leftButtons {
  width: 128px;
  height: 48px;
  border-radius: 4px;
  font-size: 16px;
}

.aboutSecond {
  height: 100vh;
  background: url(./imgs/about2bg.png) #ffffff no-repeat right center;
  background-size: 100%;
}

.aboutSecondDiv {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.aboutSecondDiv img {
  width: 6vw;
  position: relative;
  bottom: 4vw;
  left: 4vw;
}

.aboutSecondDiv .text {
  width: 38vw;
  color: #666e82;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  position: relative;
  left: 7vw;
}

.aboutSecondDiv .title1 {
  font-weight: 500;
  font-size: 40px;
  line-height: 60px;
  color: #000630;
  opacity: 0.9;
}

.aboutSecondDiv .title2 {
  margin: 20px 0;
  text-align: justify;
  text-justify: inter-ideograph;
}

.aboutThird {
  height: 90vh;
}

.aboutThird .title {
  font-weight: 500;
  font-size: 40px;
  color: #000630;
  text-align: center;
  padding: 7vw 0;
}

.aboutThird .imgs {
  overflow: hidden;
}

.aboutThird .imgs .imgItem {
  float: left;
  width: 25vw;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #666e82;
  margin-left: 6vw;
}

.aboutThird .imgItem img {
  width: 100%;
  margin-bottom: 32px;
}

.aboutFourth {
  height: 90vh;
  position: relative;
  background: url(./imgs/leftzs.svg) no-repeat 0 30%,
    url(./imgs/rightzs.svg) no-repeat right bottom;
}

.aboutFourth .titles {
  text-align: center;
  padding: 8vw 0 0;
}

.aboutFourth .titles .title1 {
  color: #000630;
  font-weight: 500;
  font-size: 40px;
  margin-bottom: 2vw;
}

.aboutFourth .titles .title2 {
  font-size: 14px;
  width: 47vw;
  margin: 0 auto;
  line-height: 22px;
  color: #666e82;
  font-size: 400;
}

.aboutFourth .imgs {
  overflow: hidden;
  width: 64vw;
  margin: 4vw auto 0;
}

.aboutFourth .imgs .imgItem {
  float: left;
  width: 10vw;
  text-align: center;
  font-weight: 500;
  font-size: 40px;
  color: #155de8;
  margin-left: 8vw;
}

.aboutFourth .imgs .imgItem:first-child {
  margin-left: 0;
}

.aboutFourth .imgItem img {
  width: 100%;
}

.aboutFifth {
  height: 100vh;
  overflow: hidden;
}

.aboutFifth .title1 {
  color: #000630;
  font-weight: 500;
  font-size: 40px;
  margin: 6vw 0 4vw;
  text-align: center;
}

.aboutFifth .fifthtabs .ant-tabs-tab {
  font-size: 24px;
  font-size: 500;
  color: #000630;
}

.fifthtabs .ant-tabs-tab-btn:hover {
  color: #448aff;
}

.aboutFifth .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: none;
}

.aboutFifth .ant-tabs-ink-bar {
  background: transparent;
}

.aboutFifth .ant-tabs-ink-bar::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: #155de8;
}

.aboutFifth .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
  height: 4px;
}

.fifthtitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.fifthtitle .tabcontent {
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background: url(./imgs/fqbg1.svg) no-repeat left center;
  background-size: 100%;
  position: relative;
}

.tabcontent .numlot {
  position: absolute;
  top: 50%;
  left: 0;
  height: 9vw;
  margin-top: -4.5vw;
}

.tabcontent .content {
  position: absolute;
  width: 50vw;
  height: 17vw;
  left: 50%;
  margin-left: -20vw;
  top: 50%;
  margin-top: -8.5vw;
  color: #666e82;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 22px;
}

.aboutSixth {
  height: 100vh;
  overflow: hidden;
  position: relative;
}

.aboutSixth .topArea {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 64px;
  padding-top: 4vw;
}

.topArea .sixthleft {
  position: relative;
  width: 600px;
  height: 600px;
  cursor: pointer;
}

.sixthleft .leftlt {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}

.sixthleft .mapimg {
  width: 75%;
  position: absolute;
  left: 16%;
  top: 16%;
}

.sixthright {
  flex: 1;
  margin-left: 4vw;
}

.sixthright .srTitle {
  text-align: left;
  color: #000630;
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 52px;
}

.sixthright .twoAddress {
  height: 300px;
}

.infoArea {
  overflow: hidden;
  display: flex;
  align-items: flex-end;
}

.infoArea .otherblock {
  overflow: hidden;
  cursor: pointer;
  position: relative;
  width: 20vw;
  max-width: 400px;
  height: 300px;
  background: #e8effd;
  border-top-left-radius: 80px;
  border-bottom-right-radius: 80px;
}

.infoArea .otherblock:hover {
  background: #ecf3ff;
}

.infoArea .info {
  border-bottom: 4px solid #155de8;
  position: relative;
  height: 300px;
  flex: 1;
}

.info .coffee {
  position: absolute;
  right: 20px;
  bottom: -4px;
  width: 80px;
}

.info .contectInfo {
  line-height: 22px;
  font-size: 14px;
  font-weight: 400;
  color: #666e82;
}

.contectInfo p a.elink {
  color: #666e82;
}

.infotitle {
  display: flex;
  align-items: center;
  color: #000630;
  font-size: 24px;
  font-weight: 500;
  margin: 48px 0;
}

.infotitle img {
  margin-right: 12px;
  position: relative;
  top: 4px;
  width: 32px;
}

.joinButton {
  cursor: pointer;
  background: #155de8;
  color: #ffffff;
  font-size: 24px;
  font-weight: 500;
  width: 288px;
  height: 68px;
  line-height: 68px;
  text-align: center;
  position: absolute;
  left: 50%;
  bottom: 6vw;
  margin-left: -144px;
  border-radius: 4px;
}

.joinButton:hover {
  background: #448aff;
}

.joinButton:active {
  background: #1233a2;
}

.aircraft {
  position: absolute;
  left: calc(50% + 120px);
  bottom: 6vw;
  width: 26vw;
}

/* ===============加入我们=============== */
.joinInfo {
  width: 70vw;
  margin: 0 auto 80px;
}

.joinInfo .title {
  color: #000630;
  font-weight: 500;
  line-height: 60px;
  font-size: 40px;
  text-align: center;
  margin-bottom: 80px;
}

.joinInfo .rightloca {
  display: flex;
  align-items: center;
  color: #000630;
  font-size: 16px;
  font-weight: 400;
  margin-right: 32px;
}

.rightloca img {
  width: 12px;
  margin-right: 8px;
}

.rightloca span {
  position: relative;
  bottom: 2px;
}

.joinInfo .panelHeader {
  font-size: 24px;
  font-weight: 500;
  color: #000000;
  position: relative;
  bottom: 10px;
  left: 10px;
}

.joinInfo .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
  padding: 0;
}

.joinInfo .collapsePanel {
  border-bottom: 1px solid #ccd1df;
  border-radius: 0;
  padding-top: 20px;
}

.panelContainer {
  background: #fbfcff;
  padding: 64px 120px;
}

.panelContainer .pcTitle {
  font-size: 16px;
  color: #000630;
  font-weight: 700;
  margin-bottom: 16px;
}

.panelContainer .content {
  font-weight: 400;
  font-size: 14px;
  color: #666e82;
  margin-bottom: 24px;
}

.emaillinkdiv {
  color: #155de8;
  font-size: 16px;
  font-weight: 500;
}

.emaillinkdiv a.emaillink {
  color: #155de8;
}

.emaillinkdiv a.emaillink:hover {
  color: #448aff;
}

.emaillinkdiv a.emaillink:active {
  color: #1233a2;
}

.joinInfo .ant-collapse > .ant-collapse-item:hover {
  background: rgba(68, 138, 255, 0.1);
}

.joinInfo .ant-collapse > .ant-collapse-item:active {
  background: rgba(18, 51, 162, 0.1);
}
