@media screen and (max-width: 1024px) {
  .aboutFirst {
    height: 100vh;
    background: url(./imgs/about1bg.svg) no-repeat bottom center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .aboutFirst .afLeft {
    margin-left: 40px;
    position: relative;
    top: -40px;
  }

  .aboutFirst .afLeft .titles {
    margin-bottom: 16px;
  }

  .afLeft .title1 {
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
    color: #000638;
  }
  .afLeft .title2 {
    font-size: 40px;
    font-weight: 700;
    line-height: 36px;
    color: #155de8;
  }

  .afLeft .title2.joinTitle2 {
    line-height: 72px;
  }

  .afLeft .title3 {
    font-size: 14px;
    font-weight: 400;
    color: #666e82;
    line-height: 28px;
    white-space: nowrap;
    letter-spacing: 0.05em;
  }

  .aboutFirst .afRight {
    width: 50vw;
  }

  .afLeft .leftButtons {
    width: 88px;
    height: 36px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
  }

  .aboutSecond {
    height: 100vh;
    background: url(./imgs/about2bg.png) #ffffff no-repeat right center;
    background-size: 100%;
  }

  .aboutSecondDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }

  .aboutSecondDiv img {
    width: 6vw;
    position: relative;
    bottom: 0.5vw;
    left: 4vw;
  }

  .aboutSecondDiv .text {
    width: 39vw;
    color: #666e82;
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    position: relative;
    left: 7vw;
  }

  .aboutSecondDiv .title1 {
    font-size: 22px;
    line-height: 32px;
    color: #000630;
    opacity: 0.9;
  }

  .aboutSecondDiv .title2 {
    margin: 8px 0;
  }

  .aboutThird {
    height: 50vh;
  }

  .aboutThird .title {
    font-size: 22px;
    color: #000630;
    text-align: center;
    padding: 0 0 7vw;
  }

  .aboutThird .imgs {
    overflow: hidden;
  }

  .aboutThird .imgs .imgItem {
    float: left;
    width: 25vw;
    text-align: center;
    font-size: 12px;
    color: #666e82;
    margin-left: 6vw;
  }

  .aboutThird .imgItem img {
    width: 100%;
    margin-bottom: 26px;
  }

  .aboutFourth {
    height: 65vh;
    position: relative;
    background: url(./imgs/leftzs.svg) no-repeat 0 30%,
      url(./imgs/rightzs.svg) no-repeat right bottom;
    background-size: 80px, 106px;
  }

  .aboutFourth .titles {
    text-align: center;
    padding: 10vw 0 0;
  }

  .aboutFourth .titles .title1 {
    color: #000630;
    font-size: 22px;
    margin-bottom: 2vw;
  }

  .aboutFourth .titles .title2 {
    font-size: 12px;
    width: 70vw;
    margin: 0 auto;
    line-height: 18px;
    color: #666e82;
    font-size: 400;
  }

  .aboutFourth .imgs {
    overflow: hidden;
    width: 64vw;
    margin: 4vw auto 0;
  }

  .aboutFourth .imgs .imgItem {
    float: left;
    width: 10vw;
    text-align: center;
    font-weight: 500;
    font-size: 22px;
    color: #155de8;
    margin-left: 8vw;
  }

  .aboutFourth .imgs .imgItem:first-child {
    margin-left: 0;
  }

  .aboutFourth .imgItem img {
    width: 100%;
  }

  .aboutFifth {
    height: 80vh;
  }

  .aboutFifth .title1 {
    color: #000630;
    font-size: 22px;
    margin: 6vw 0 4vw;
    text-align: center;
  }

  .aboutFifth .fifthtabs .ant-tabs-tab {
    font-size: 14px;
    font-size: 500;
    color: #000630;
  }

  .fifthtabs .ant-tabs-tab-btn:hover {
    color: #448aff;
  }

  .aboutFifth .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }

  .aboutFifth .ant-tabs-ink-bar {
    background: transparent;
  }

  .aboutFifth .ant-tabs-ink-bar::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background: #155de8;
  }

  .aboutFifth .ant-tabs-top > .ant-tabs-nav .ant-tabs-ink-bar {
    height: 4px;
  }

  .fifthtitle {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
  }

  .fifthtitle .tabcontent {
    width: 100%;
    flex: 1;
    box-sizing: border-box;
    background: url(./imgs/fqbg1.svg) no-repeat left center;
    background-size: 100%;
    position: relative;
  }

  .tabcontent .numlot {
    position: absolute;
    top: 50%;
    left: 0;
    height: 9vw;
    margin-top: -4.5vw;
  }

  .tabcontent .content {
    position: absolute;
    width: 60vw;
    height: 17vw;
    left: 50%;
    margin-left: -26vw;
    top: 50%;
    margin-top: -8.5vw;
    color: #666e82;
    font-weight: 400;
    font-size: 12px;
    display: flex;
    align-items: center;
    line-height: 18px;
  }

  .aboutSixth {
    height: 80vh;
    overflow: hidden;
    position: relative;
  }

  .aboutSixth .topArea {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 64px;
  }

  .topArea .sixthleft {
    position: relative;
    width: 378px;
    height: 378px;
    cursor: pointer;
  }

  .sixthleft .leftlt {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .sixthleft .mapimg {
    width: 75%;
    position: absolute;
    left: 16%;
    top: 16%;
  }

  .sixthright {
    flex: 1;
    margin-left: 4vw;
  }

  .sixthright .srTitle {
    text-align: left;
    color: #000630;
    font-size: 22px;
    margin-bottom: 19px;
  }

  .sixthright .twoAddress {
    height: 160px;
  }

  .infoArea {
    overflow: hidden;
    display: flex;
    align-items: flex-end;
  }

  .infoArea .otherblock {
    overflow: hidden;
    cursor: pointer;
    position: relative;
    width: 20vw;
    max-width: 300px;
    height: 160px;
    background: #e8effd;
    border-top-left-radius: 42px;
    border-bottom-right-radius: 42px;
  }

  .infoArea .otherblock:hover {
    background: #ecf3ff;
  }

  .infoArea .info {
    border-bottom: 4px solid #155de8;
    position: relative;
    height: 160px;
    flex: 1;
  }

  .info .coffee {
    position: absolute;
    right: 20px;
    bottom: -4px;
    width: 60px;
  }

  .info .contectInfo {
    line-height: 12px;
    color: #666e82;
  }

  .infotitle {
    display: flex;
    align-items: center;
    color: #000630;
    font-size: 14px;
    font-weight: 500;
    margin: 26px 0;
  }

  .infotitle img {
    margin-right: 4px;
    position: relative;
    top: 4px;
    width: 16px;
  }

  .joinButton {
    cursor: pointer;
    background: #155de8;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    width: 160px;
    height: 36px;
    line-height: 34px;
    text-align: center;
    position: absolute;
    left: 50%;
    bottom: 6vw;
    margin-left: -80px;
    border-radius: 4px;
  }

  .joinButton:hover {
    background: #448aff;
  }

  .joinButton:active {
    background: #1233a2;
  }

  .aircraft {
    position: absolute;
    left: calc(50% + 70px);
    bottom: 6vw;
  }

  /* ===============加入我们=============== */
  .joinInfo {
    width: 70vw;
    margin: 0 auto 80px;
  }

  .joinInfo .title {
    color: #000630;
    line-height: 32px;
    font-size: 22px;
    text-align: center;
    margin-bottom: 64px;
  }

  .joinInfo .rightloca {
    display: flex;
    align-items: center;
    color: #000630;
    font-size: 12px;
    font-weight: 400;
    margin-right: 32px;
  }

  .rightloca img {
    width: 12px;
    margin-right: 8px;
  }

  .rightloca span {
    position: relative;
    bottom: 0;
  }

  .joinInfo .panelHeader {
    font-size: 14px;
    color: #000000;
    position: relative;
    bottom: 8px;
    left: 10px;
  }

  .joinInfo .ant-collapse .ant-collapse-content > .ant-collapse-content-box {
    padding: 0;
  }

  .panelContainer {
    background: #fbfcff;
    padding: 64px 120px;
  }

  .panelContainer .pcTitle {
    font-size: 16px;
    color: #000630;
    font-weight: 700;
    margin-bottom: 16px;
  }

  .panelContainer .content {
    font-weight: 400;
    font-size: 14px;
    color: #666e82;
    margin-bottom: 24px;
  }

  .emaillinkdiv {
    color: #155de8;
    font-size: 16px;
    font-weight: 500;
  }

  .emaillinkdiv a.emaillink {
    color: #155de8;
  }

  .emaillinkdiv a.emaillink:hover {
    color: #448aff;
  }

  .emaillinkdiv a.emaillink:active {
    color: #1233a2;
  }

  .joinInfo .ant-collapse > .ant-collapse-item:hover {
    background: rgba(68, 138, 255, 0.1);
  }

  .joinInfo .ant-collapse > .ant-collapse-item:active {
    background: rgba(18, 51, 162, 0.1);
  }
}
